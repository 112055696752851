<template>
	<div class="page-content">
		<b-row>
			<b-col md="10">
				<page-breadcrumb title="Level Management" class="mb-3" />
			</b-col>
		</b-row>
		<data-create-popup title="Create Level" :default-data="default_data"
			:create-data-fn="createItem" @create="$refs.group_list.getList()" 
		/>
		<data-table-ssr id="group_list" ref="group_list" 
			:columns="fields" 
			:get-list-fn="getList" 
			:to-edit-fn="toEditPage"
			:paginationPosition="'top'"
			:setCurrentPage="parseInt($route.params.page)"
		>
			<template #slot_cards="{slotData}">
				<span :style="{color: slotData.amount_card % 3 === 0 ? 'green' : 'red'}">{{ slotData.amount_card }}</span>
			</template>
			<template #slot_avg_titles_per_item="{slotData}">
				<span>{{ slotData.items.length ? Math.round((slotData.amount_card / slotData.items.length) * 100) / 100 : '' }}</span>
			</template>
			<template #slot_avg_time_per_card="{slotData}">
				<span>{{ slotData.amount_card ? Math.round((slotData.playing_time / slotData.amount_card) * 100) / 100 : '' }}</span>
			</template>
			<template #slot_avg_cards_per_blocker="{slotData}">
				<span>{{ slotData.blocker_amount ? Math.round((slotData.amount_card / slotData.blocker_amount) * 100) / 100 : '' }}</span>
			</template>
		</data-table-ssr>
	</div>
</template>
  
<script>
import service from "../service";
import default_data from '../defaultData'
import boosterService from '../../booster/service';
import supportItemService from '../../support_item/service';
const create_schema = [
	{
		cols: 12,
		fields: [
			{
				label: 'Level', field: 'level', input_type: 'select',
				options: [{ text: 'Normal', value: 1 }, { text: 'Hard', value: 2 }, { text: 'Super Hard', value: 3 }],
				validate: { required: true }
			},
			{ label: 'Playing time', field: 'playing_time', input_type: 'number', validate: { required: true } },
		]
	}
];

const fields = [
	{ label: 'Title', field: 'title', value_handler: (model)=>{return `Level ${model.display_order}`} },
	{
		label: 'Level', field: 'level', input_type: 'select',
		options: [{ text: 'Normal', value: 1 }, { text: 'Hard', value: 2 }, { text: 'Super Hard', value: 3 }]
	},
	{ label: 'Items', field: 'items', value_handler: (model)=>{return model.items.length} },
	{ label: 'Cards', field: 'cards', input_type: 'slot' },
	{ label: 'Average tiles/item', field: 'avg_titles_per_item', input_type: 'slot' },
	{ label: 'Time', field: 'playing_time' },
	{ label: 'Average time/card', field: 'avg_time_per_card', input_type: 'slot' },
	{ label: 'Blockers', field: 'blocker_amount' },
	{ label: 'Average cards/blocker', field: 'avg_cards_per_blocker', input_type: 'slot'}
];
export default {
	props: ['page'],
	data() {
		return {
			create_schema,
			fields,
			default_data: {
				level: 1,
				playing_time: 150,
				boosters: [],
				support_items: [],
			},
		};
	},
	methods: {
		async getList({ limit, page, query }) {
			if (page === 0){
				page = 1;
			}
			if (page != this.$route.params.page){
				this.$router.replace({ name: 'level-list', params: { page: page } });
			}
			let response_data = await service.getList({
				query: JSON.stringify({ ...query }),
				page, limit
			});
			let list = [], total = 0;
			if (response_data) {
				list = response_data.list;
				total = response_data.total;
				this.total = total;
			}
			list = list.map((item)=>{
				item.blocker_amount = item.layers.reduce((blocker_amount, item)=>{
					let blockers = item.layer.filter(node => node.block_type);
					return blocker_amount + blockers.length;
				}, 0)
				return item
			})
			return { list, total };
		},
		async createItem() {
			if (!this.default_data.boosters.length){
				let boosters = await boosterService.getAll();
				this.default_data.boosters = boosters.map(item => item._id)
			}
			if (!this.default_data.support_items.length){
				let support_items = await supportItemService.getAll();
				this.default_data.support_items = support_items.map(item => item._id)
			}
			let new_item = await service.create({ ...this.default_data, display_order: this.total });
			this.$router.push({ name: 'level-detail', params: { id: new_item._id } });
			return new_item;
		},
		toEditPage({ _id }) {
			let newTab = this.$router.resolve({ name: 'level-detail', params: { id: _id, current_page: this.current_page } });
			window.open(newTab.href, '_blank');
		},
	},
};
</script>
  